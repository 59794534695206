@use "@els/els-styleguide-core/scss/core" as *;

.c-hra-remediation-performance {

  &__filter-item {
    margin-bottom: $els-space-2x;
  }

  &__custom-date-item {
    margin-top: $els-space-3o4 - $els-space-1o8;
  }

  &__white-space {
    padding: $els-space-2x;
    background-color: $els-color-background;
    border-bottom: $els-thin-keyline-width solid $els-color-n3;
    margin-bottom: $els-space-2x;
  }

  &__summary {
    margin-top: $els-space-2x;
    margin-bottom: $els-space-3x;
  }

  &__summary-item {
    margin-right: $els-space-1x;
  }

  &__summary-icon {
    color: $els-color-elsevier-orange;
    margin-right: $els-space-1o2;
  }

  &__summary-subtitle {
    display: block;
    margin-right: $els-space-2x;
  }

  &__table {
    margin-top: $els-space-2x;
    overflow-x: auto;
  }
}
