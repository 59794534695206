@use "@els/els-styleguide-core/scss/core" as *;

button {
  color: $els-color-text;
}

button:focus-visible,
[type="button"]:focus-visible,
[type="reset"]:focus-visible,
[type="submit"]:focus-visible {
  @include focus-default;
}

.s-els-authored-content {
  .section-title-1 {
    display: block;
    margin-top: $els-space-1x;
    margin-bottom: $els-space-1o2;
    font-size: $els-fs-h2;
  }
}

.u-hra-max-width-5x {
  max-width: $els-space * 5;
}

.s-hra-disable-flex-wrap .o-els-flex-layout--wrap {
  flex-wrap: nowrap;
}
