@use "@els/els-styleguide-core/scss/core" as *;

.c-hesi-score-range {

  &__box {
    background: white;
    border: $els-color-n3 solid $els-thin-keyline-width;
    border-radius: $els-thick-keyline-width $els-thick-keyline-width $els-space-1o4 $els-space-1o4;
    height: 100%;
  }

  &__inner {
    padding: $els-space;
  }

  &__bar {
    height: $els-space;
  }

  &__item {

    &--recommended {
      .c-hesi-score-range__bar {
        background-color: $els-color-extended-green-8;
      }
    }

    &--acceptable {
      .c-hesi-score-range__bar {
        background-color: $els-color-extended-green-4;
      }
    }

    &--below_acceptable {
      .c-hesi-score-range__bar {
        background-color: $els-color-extended-orange-4;
      }
    }

    &--needs_remediation {
      .c-hesi-score-range__bar {
        background-color: $els-color-extended-red-9;
      }
    }
  }

  &__label {
    display: flex;
    align-items: flex-end;
    height: $els-space-3x;
  }

  &__range {
    margin-bottom: $els-space;
  }
}
